
.btnshadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
}
.greenball{
position: relative;
}
.greenballavailable{
position:absolute;
left: 95%;
top:-20px
}
.greenball1{
    position: relative;
    }
    
    .greenballavailable1{
    position:absolute;
    left: 250%;
    top:-20px
    }

.searchList li {
 padding-top: 15px;
 padding-left: 56px;
     padding-bottom: 15px;
 
}
/* .btn{
    &:hover + .box {
        transform: translate(100px, 0);
    }
}

.box {
    width: 100px;
    height: 100px;
    transition: transform 330ms ease-in-out;
} */

.popup-overlay{
    background-color: rgb(109, 108, 109);
     
}
.popup-content{
    width: 63% !important;
}
.zone_pops-overlay> div{
    width:  500px !important;
}
.jobCard_popup-content{
    width:   45% !important;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.ex1 {

    padding: inherit;
    justify-items: center;
    background-color: rgb(255, 255, 255);
    height:500px;
    width: 100%;
    overflow-y: scroll;
  }

  .editAccess_flow{
      
    padding: inherit;
    justify-items: center;
      
    width:800px;
    overflow-x: scroll;
  }

  .scroll_bar_Manpower{
    height: 300px; 
    overflow-y: scroll;
  }
  .scroll_bar_ManpowerMulti{
    height: 350px; 
    overflow-y: scroll;
  }

  .planned_table thead tr:first-child th{
    position: sticky;
    top: 0;
  }

  .plann_scroll{
    height: 150px; 
    overflow-y: scroll;
  }
  .secrchplace::placeholder{
      font-size: 15px;
  }

   .disabledclass{
        opacity: 0.2;
        cursor: not-allowed;
   }

   .fileSheet{
    opacity: 0;
    /* cursor: pointer !important; */
    height: 40px;
   }
   .shhetText{
       font-size: 14px;
       margin-left: -94px;
       cursor: pointer !important;
       color: #8F9BBA;
       margin-top: 3px;
   }

.height_bale{
    height: 20px !important;
}

   .productiveSeacrhch{
       /* height: 200px; */
       overflow-y: scroll;
   }

   .gang_product_input:focus-visible{
    outline: none;
   }

   .quntity_input{
    font-weight: bold;
   }

   @media (min-width: 1140px) and (max-width: 1290px) {
    .responcev_btn{
width: 120px !important;
    }
   }

   @media (min-width: 940px) and (max-width: 1140px) {
    .responcev_btn{
width: 100px !important;
    }
   }

   .searchWrapper{
       /* border: none !important;
       margin-top: -20px !important; */
       /* margin-top: 40px; */
   }
   #search_input::placeholder{
       /* color: transparent !important; */
   }

.auto-suggetion-custom ul.suggestions-list {
    position: absolute;
    background: #fff;
    z-index: 99;
    border: 1px solid;
    width: 100%;
}
.auto-suggetion-custom .user-input {
  width: 250px;
  padding: 5px 3px;
}
.auto-suggetion-custom .suggestions-list {
  list-style: none;
  padding: 0;
  max-height: 160px;
  overflow-y: auto;
  max-width: 250px;
}
.auto-suggetion-custom .suggestion {
  padding: 10px;
  color: rgb(65, 65, 65);
  cursor: pointer;
  z-index: 9999;
}
.main_activity_check {
  height: 10px;
  width: 10px;
  background: green;
  margin-left: 5px;
  border-radius: 50%;
}
.loading-layout{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-layout .loading-spinner{
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}